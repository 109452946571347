import React from 'react';
import PropTypes from 'prop-types';
import './button.css';
import downloadFile from '../../../content/press/WOLFMICH PRESSETEXT.pdf';

const Button = ({ text }) => (
  <section id="intro">
    <div id="intro-content" className="center-content">
      <div className="center-content-inner">
        <div className="content-section content-section-margin">
          <div className="content-section-grid clearfix">
            <a href={downloadFile} className="button nav-link" download>
              <div className="bottom"></div>

              <div className="top">
                <div className="label">{text}</div>

                <div className="button-border button-border-left"></div>
                <div className="button-border button-border-top"></div>
                <div className="button-border button-border-right"></div>
                <div className="button-border button-border-bottom"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Button;
