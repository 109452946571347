import React from 'react';
import Layout from 'components/layout';
import Button from 'components/button';

const Press = () => (
  <Layout>
    <div style={{ height: '20vh' }} />
    <Button text={'Presse Text'} />
  </Layout>
);

export default Press;
